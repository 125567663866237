import React, { Component, Fragment } from 'react';
import cls from 'classnames';
import style from './header.style.scss';
import { logo } from './assets';
import StagesProgressBarComponent from '@components/stagesProgressBar/stagesProgressBar.component';
import { observer } from 'mobx-react';
import { appState } from '@state/index';

export type Props = {

};
@observer
export default class HeaderComponent extends Component<Props> {
    render() {
        return (
            <React.Fragment>
                <div className={cls(style.container, { [style.resultsHeader]: appState.didFinishStages })}>
                    <img className={style.logo} src={logo} />
                </div>
                {appState.didFinishStages ? <div className={style.placeholder}></div> :
                    <Fragment>
                        <StagesProgressBarComponent className={style.stages} />
                        <div className={style.placeholderNoProgress}></div>
                    </Fragment>
                }
            </React.Fragment>
        );
    }
}
