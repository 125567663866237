export default class PersistStore {
    private _store: Object;
    private _name: string;
    constructor(store: Object, name: string) {
        this._store = store;
        this._name = name;
    }
    save() {
        const json = JSON.stringify(this._store);
        if (typeof window !== 'undefined') {
            localStorage.setItem(this._name, json);
        }
    }

    load() {
        if (typeof window !== 'undefined') {
            const item = localStorage.getItem(this._name);
            try {
                const parsed = JSON.parse(item);
                for (const key of Object.keys(parsed)) {
                    this._store[key] = parsed[key];
                }
            } catch (err) {

            }
        }
    }
}
