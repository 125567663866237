import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './slider.style.scss';
import { appState } from '@state/index';
import Slider, { Range, Handle } from 'rc-slider';
import 'rc-slider/assets/index.css';

export type Props = {
    min: number;
    max: number;
    value: number;
    desc?: string;
    step?: number;
    onChange?: (value: number) => any;
};
@observer
export default class SliderComponent extends Component<Props> {
    handle = (props) => {
        const { value, dragging, index, className, ...restProps } = props;
        return (
            <Handle value={value} className={cls(className, style.handle)} {...restProps}>
            </Handle>
        );
    }
    onChange = (e) => {
        this.props.onChange?.(e);
    }
    render() {
        return (
            <div className={style.container}>
                    <Slider
                        value={this.props.value}
                        min={this.props.min}
                        max={this.props.max}
                        step={this.props.step || 1}
                        handle={this.handle}
                        trackStyle={{ backgroundColor: '#FF9900' }}
                        onChange={this.onChange}
                    />
                    <div className={style.textContainer}>
                        <div className={style.minmax}>{this.props.min}</div>
                        { this.props.desc ? <div className={style.desc}>{this.props.desc}</div> : null }
                        <div className={style.minmax}>{this.props.max}</div>
                    </div>
            </div>
        );
    }
}
