import { observable } from 'mobx';
import PersistStore from '@utils/persistStore.util';

export class UserData {
    @observable age: number = 18;
    @observable investmentAmt: number = null;
    @observable investmentType: string = null;
    @observable investmentGoal: string = null;
    @observable investmentLiquid: string = null;
    @observable investmentRisk: string = null;
    @observable investmentDurable: string = null;
    @observable investmentCrisis: string = null;

    constructor() {
        const persister = new PersistStore(this, 'user-progress');
        persister.load();
        setInterval(() => {
            persister.save();
        }, 1000);
    }
}

export class FileData {
    @observable investmentAmt: number = 30000;
    @observable investmentDuration: number = 5;

    constructor() {
        const persister = new PersistStore(this, 'user-file-data');
        persister.load();
    }

    store() {
        const persister = new PersistStore(this, 'user-file-data');
        persister.save();
    }

    getMaxAmt() {
        return 1200000;
    }
    getMaxDuration() {
        return 25;
    }

    getStocks() {
        const score = this.getSolidity();
        const stocks = {
            'Israel shares': 0.4,
            'Overseas Stocks': 0.3,
            'Israeli government': 0.01,
            'Concerns of Israel': 0.09,
            'Concerns of Overseas': 0.05,
            'Short shekel': 0.05,
        };
        return stocks;
    }

    getSolidity() {
        const investmentDuration = Math.round((this.investmentDuration / this.getMaxDuration()) * 100);
        const investmentRange = Math.round((this.investmentAmt / this.getMaxAmt()) * 100);
        const clacScore = (investmentDuration * 0.5) + (investmentRange * 0.5);
        const score = Math.round((clacScore / 100) * 7);
        return score;

    }

    getScoreText() {
        const text = {
            1: 'Very Solid',
            2: 'Solid',
            3: 'Solid',
            4: 'Edgy',
            5: 'Slighly Risky',
            6: 'Risky',
            7: 'Very Risky',
        };
        if (this.getSolidity() === 0) { return text[1]; }
        return text[this.getSolidity()];
    }
}

export default class AppState {
    @observable totalStages: number = 0;
    @observable currentStageIndex: number = 0;
    @observable userData: UserData = new UserData();
    @observable fileData: FileData = new FileData();
    @observable stageError: string = null;
    @observable screenWidth: number = typeof window !== 'undefined' ? window.outerWidth : 800;
    @observable didFinishStages: boolean = false;

    constructor() {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', () => {
                this.screenWidth = window.outerWidth;
            });
        }
    }

    nextStage() {
        if (this.currentStageIndex !== 0 && this.currentStageIndex + 1 === this.totalStages) {
            this.didFinishStages = true;
        } else {
            const nextIndex = Math.min(this.currentStageIndex + 1, this.totalStages - 1);
            this.currentStageIndex = nextIndex;
            this.didFinishStages = false;
        }
        console.log(`currentIndex : ${this.currentStageIndex}, didFinish : ${this.didFinishStages}, total : ${this.totalStages}`);
    }

    prevStage() {
        const nextIndex = Math.max(this.currentStageIndex - 1, 0);
        this.currentStageIndex = nextIndex;
        console.log(`currentIndex : ${this.currentStageIndex}, didFinish : ${this.didFinishStages}, total : ${this.totalStages}`);
    }

    onFinishProcessing() {
        if (typeof window !== 'undefined') {
            window.location.href = '/file';
        }
    }
}
