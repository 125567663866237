import React, { Component } from 'react';
import cls from 'classnames';
import style from './pageLayout.style.scss';
import HeaderComponent from '@components/header/header.component';
import { appState } from '@state/index';
import { observer } from 'mobx-react';

export type Props = {
    forceHeight?: boolean;
};
@observer
export default class PageLayoutComponent extends Component<Props> {
    render() {
        return (
            <div className={style.container}>
                <HeaderComponent />
                <div className={cls(style.content, { [style.noProgress]: appState.didFinishStages, [style.forceHeight]: this.props.forceHeight })}>
                    { this.props.children }
                </div>
            </div>
        );
    }
}
