import React, { Component } from 'react';
import cls from 'classnames';
import style from './stagesProgressBar.style.scss';
import { appState } from '@state/index';
import { observer } from 'mobx-react';

export type Props = {
    className: string;
};
@observer
export default class StagesProgressBarComponent extends Component<Props> {
    render() {
        const stages = appState.totalStages > 0 ? Array.from(Array(appState.totalStages - 1).keys()) : [];
        return (
            <div className={cls(style.container, this.props.className)} style={{ gridTemplateColumns: `repeat(${appState.totalStages - 1}, 1fr)` }}>
                { stages.map(x => (
                    <div key={x} className={cls(style.stage)}>
                        <div className={cls(style.fill, { [style.filled]: x <= appState.currentStageIndex - 1 })}></div>
                    </div>
                ))}
            </div>
        );
    }
}
